<script lang="ts" setup>
import {
  getCurrentInstance,
  nextTick,
  onDeactivated,
  onMounted,
  ref,
} from "vue";
import $router from "@/router";
import Card from "@/components/Card.vue";
import skrollr from "skrollr";

const skrollrobj = ref(null);
const cardList = ref([
  {
    dataImage: new URL("@/assets/image/Canyons.avif", import.meta.url).href,
    title: "Canyons",
    text: "Enter the literature module.",
    router: "elaina",
    type: 1,
  },
  {
    dataImage: new URL("@/assets/image/Beaches.avif", import.meta.url).href,
    title: "Beaches",
    text: "Enter the picture module.",
    router: "ai",
    type: 2,
  },
  {
    dataImage: new URL("@/assets/image/Trees.avif", import.meta.url).href,
    title: "Trees",
    text: "Enter the video module.",
    type: 3,
  },
  {
    dataImage: new URL("@/assets/image/Lakes.avif", import.meta.url).href,
    title: "Lakes",
    text: "One more card to play with.",
    type: 4,
  },
]);
import { useTokenStore } from "@/store/modules/user";

const useToken = useTokenStore();
// const fileList = ref([])
const fType = ref(0);
const { proxy } = getCurrentInstance() as any;

onMounted(() => {
  nextTick(() => {
    skrollrobj.value = skrollr.init({
      forceHeight: false,
      smoothScrolling: true,
      smoothScrollingDuration: 500,
      easing: {
        vibrate: function (p: number) {
          return Math.sin(p * 10 * Math.PI);
        },
      },
    });
  });
});
onDeactivated(() => {
  const instance = skrollr.get();
  instance.destroy();
});
const run = function (type: number) {
  if (useToken.token) {
    fType.value = type;
    proxy.$refs.uploadBtn.click();
  }
};
const toRouter = function (item: { router: any }) {
  if (item.router) {
    $router.push({
      path: item.router,
    });
  }
};
</script>

<template>
  <div
    class="page-main"
    data-400="padding-top: 200px"
    data-600="padding-top: 0px"
  >
    <div
      class="sky skrollable rendered"
      data-0="background: rgb(74,74,74); top: 0px"
      data-300="background: rgb(140,201,220); top: 0px"
      data-600="top: -200px"
      style="background: rgb(74, 74, 74)"
    >
      <div class="sky-inner">
        <div class="title">
          <img src="@/assets/logo.png" alt="Elaina" class="logo" />
          <img
            src="@/assets/image/stars.png"
            alt="Stars"
            class="stars skrollable rendered"
            data-100="top: 10%; left: 22%;"
            data-300="top: -100%; left: 30%;"
            style="top: 10%; left: 22%"
          />
          <img
            src="@/assets/image/moon.png"
            alt="Moon"
            class="moon skrollable rendered"
            data-100="top: 10%;"
            data-300="top: -100%;"
            style="top: 10%"
          />
          <img
            src="@/assets/image/sun.png"
            alt="Sun"
            class="sun skrollable unrendered"
            data-200="top: 100%; right: 20%;"
            data-300="top: 10%; right: 0%;"
            style="top: 100%; right: 20%"
          />
        </div>
      </div>
    </div>
    <h2 class="wel-title">我是！我是！我是！我是一只哈士奇！</h2>
    <div class="container">
      <div class="cards">
        <Card
          v-for="(item, index) in cardList"
          :key="index"
          :data-image="item.dataImage"
        >
          <template v-slot:header>
            <h1 @click="toRouter({ router: item.router })">{{ item.title }}</h1>
          </template>
          <template v-slot:content>
            <a @click="run(item.type)">{{ item.text }}</a>
          </template>
        </Card>
      </div>
    </div>
    <router-view />
  </div>
</template>

<style lang="scss" scope>
.page-main {
  position: relative;

  width: 100%;

  min-height: 100vh;

  background-color: #bcaaa4;
}

a {
  color: #42b983;
}

.wel-title {
  color: #5d4037;

  font-style: italic;
}

.container {
  font-size: 14px;

  font-weight: 500;

  .cards {
    display: flex;

    flex-wrap: wrap;

    justify-content: center;

    padding: 40px 80px;
  }

  p {
    line-height: 1.5em;
  }

  h1 + p,
  p + p {
    margin-top: 10px;
  }
}

.sky {
  position: fixed;

  z-index: 2;

  width: 100%;

  height: 200px;

  background: #8cc9dc;
}

.sky-inner {
  position: relative;

  overflow: hidden;

  width: 80%;

  height: 100%;

  min-height: 200px;

  margin: 0 auto;
}

.title {
  height: 100%;

  min-height: 200px;
}

.title img {
  position: absolute;

  height: 80%;

  vertical-align: middle;
}

.title img.logo {
  left: 0;

  max-width: 197px;

  max-height: 170px;

  margin-top: 20px;
}

.title img.stars {
  left: 22%;

  max-width: 619px;

  max-height: 189px;

  margin: 0 4%;
}

.title img.moon {
  right: 0;

  max-width: 95px;

  max-height: 120px;

  margin-top: 20px;
}

img.sun {
  right: 0;

  max-width: 163px;

  max-height: 163px;
}
</style>
