import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-info" }

import { computed, onMounted, ref, getCurrentInstance } from "vue";


export default /*@__PURE__*/_defineComponent({
  ...{
  name: "Card",
  inheritAttrs: false,
},
  __name: 'Card',
  props: {
  dataImage: {
    type: String,
    default: "",
  },
},
  setup(__props) {

const { proxy } = getCurrentInstance() as any;

const props = __props;

const width = ref(0);
const height = ref(0);
const mouseX = ref(0);
const mouseY = ref(0);
const mouseLeaveDelay = ref();
onMounted(() => {
  setInterval(() => {
    width.value = proxy.$refs.card?.offsetWidth;
    height.value = proxy.$refs.card?.offsetHeight;
  });
});
const mousePX = computed<number>(() => {
  return mouseX.value / width.value;
});
const mousePY = computed<number>(() => {
  return mouseY.value / height.value;
});
const cardStyle = computed(() => {
  const rX = mousePX.value * 30;
  const rY = mousePY.value * -30;
  return {
    transform: `rotateY(${rX}deg) rotateX(${rY}deg)`,
  };
});
const cardBgTransform = computed(() => {
  const tX = mousePX.value * 30;
  const tY = mousePY.value * -30;
  return {
    transform: `translateX(${tX}px) translateY(${tY}px)`,
  };
});
const cardBgImage = computed(() => {
  return {
    backgroundImage: `url(${props.dataImage})`,
  };
});

const handleMouseMove = function (e) {
  mouseX.value = e.pageX - proxy.$refs.card?.offsetLeft - width.value / 2;
  mouseY.value = e.pageY - proxy.$refs.card?.offsetTop - height.value / 2;
};
const handleMouseEnter = function () {
  clearTimeout(mouseLeaveDelay.value);
};
const handleMouseLeave = function () {
  mouseLeaveDelay.value = setTimeout(() => {
    mouseX.value = 0;
    mouseY.value = 0;
  }, 1000);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "card-wrap",
    onMousemove: handleMouseMove,
    onMouseenter: handleMouseEnter,
    onMouseleave: handleMouseLeave,
    ref: "card"
  }, [
    _createElementVNode("div", {
      class: "card",
      style: _normalizeStyle(cardStyle.value)
    }, [
      _createElementVNode("div", {
        class: "card-bg",
        style: _normalizeStyle([cardBgTransform.value, cardBgImage.value])
      }, null, 4),
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "header"),
        _renderSlot(_ctx.$slots, "content")
      ])
    ], 4)
  ], 544))
}
}

})