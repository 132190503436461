import axios from "axios";
import qs from "qs";
import { useTokenStoreHook } from "@/store/modules/user";

const ignoreUrl = ["https://api.apiopen.top/api/sentences"];
axios.defaults.baseURL = "";
//post请求头
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
//允许跨域携带cookie信息
axios.defaults.withCredentials = true;
//设置超时
axios.defaults.timeout = 5000;

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    const url = config.url || "";
    // 在发送请求之前做些什么
    if (ignoreUrl.includes(url)) {
      return config;
    }
    const tokenStore = useTokenStoreHook();
    if (tokenStore.token) {
      config.headers["X-Auth-Token"] = tokenStore.token;
    } else {
      if (url === "/api/dwzb/add") {
        alert("你没权限的啊");
        window.location.href = "/";
      }
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    if (response.status == 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
/**
 * @param {string} url
 * @param {any} data
 * @returns Promise
 */
const postApi = function (url: string, data: any) {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url,
      data: qs.stringify(data),
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 * @param {string} url
 * @param {any} data
 * @returns Promise
 */
const getApi = function (url: string, data: any) {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      params: data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 * @param {string} url
 * @param {any} data
 * @returns Promise
 */
const formDataPostApi = function (url: string, data: any) {
  const formData = new FormData();
  for (const key in data) {
    const ele = data[key];
    formData.append(key, ele);
  }
  axios.defaults.headers.post["Content-Type"] = "multipart/form-data；";
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url,
      data: data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export { postApi, getApi, formDataPostApi };
