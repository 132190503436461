import { RouteRecordRaw } from "vue-router";

type MyRoutet = RouteRecordRaw & {
  auth?: boolean;
  meta?: {
    title?: string;
  };
};
const BeforeEnter = (
  to: any,
  from: any,
  next: any,
  routes: Array<MyRoutet>
) => {
  // 1.在路由数组中找当前页面路由的对应路由项
  const fineRouter = (
    routes: Array<MyRoutet>,
    name: string
  ): MyRoutet | null => {
    for (const item of routes) {
      if (item.name === name) {
        return item;
      }
      if (item.children) {
        // 如果有子路由，查找子路由
        // 注意：1）因为传入的path是当前完整路径，子路由的path也需要设置完整的路径，例如：/my/order，而不是 order
        //      2）不可配置动态参数路由，例如：/product/:id
        if (fineRouter(item.children, name)) {
          return fineRouter(item.children, name);
        }
      }
    }
    return null; // 没有找到录音配置，返回null，由judgeRouter方法跳转404页面
  };
  // 2.路由守卫判断
  const judgeRouter = (
    to: any,
    from: any,
    next: any,
    routes: Array<MyRoutet>
  ) => {
    console.log("==============judge:", to.name, from.name);
    // 2.1路由数组找路由项
    const findRoute = fineRouter(routes, to.name);
    // 2.2没找到，说明没有这个路由，直接404
    if (!findRoute) {
      next({ name: "404" });
      return;
    }
    // 2.3更新页面标题
    if (findRoute?.meta?.title) {
      document.title = findRoute.meta.title;
    }
    if (findRoute.auth) {
      // 用户未登陆
      if (!localStorage.getItem("token")) {
        next({ name: "401" });
        return;
      }
    }
    next();
  };
  // 3. 监听页面路由改变。然后组件重新加载，又重新校验权限。
  return judgeRouter(to, from, next, routes);
};
export default BeforeEnter;
