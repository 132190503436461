import { createApp } from "vue"; // 拿过来
import app from "../App.vue"; // 拿过来
import type { App } from "vue";
import { createPinia } from "pinia";

const store = createPinia(); // 创建pinia实例

createApp(app).use(store); // 直接给他挂上边，就解决啦
// 全局注册 store
export function setupStore(app: App<Element>) {
  app.use(store); // 挂载pinia
}

export { store };
