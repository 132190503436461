import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { setupStore } from "@/store";

import "normalize.css/normalize.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElIcon from "@element-plus/icons-vue";

const app = createApp(App);
// 全局注册 状态管理(store)
setupStore(app);
for (const iconName in ElIcon) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  app.component(iconName, ElIcon[iconName]);
}
app.use(router).use(ElementPlus).mount("#app");
