import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import BeforeEnter from "./beforeEnter";
import Main from "@/views/Main.vue";

type MyRoutet = RouteRecordRaw & {
  auth?: boolean; // 是否开启登陆验证
  meta?: {
    title?: string;
  };
};
const routes: Array<MyRoutet> = [
  {
    path: "/",
    name: "/",
    component: () => import("../views/login/index.vue"),
    meta: { hidden: true },
    auth: false,
  },
  {
    path: "/main",
    name: "Main",
    component: Main,
    children: [
      {
        path: "/children",
        name: "Children",
        component: () =>
          import(/* webpackChunkName: "elaina" */ "../views/Children.vue"),
      },
    ],
    auth: false,
  },
  {
    path: "/list",
    name: "List",
    component: () => import(/* webpackChunkName: "List" */ "../views/List.vue"),
    meta: { hidden: true },
    auth: false,
  },
  {
    path: "/ai",
    name: "Ai",
    component: () => import(/* webpackChunkName: "ai" */ "../views/Ai.vue"),
    meta: { hidden: true },
    auth: true,
  },
  {
    path: "/401",
    name: "401",
    component: () => import("@/views/error/401.vue"),
    meta: { hidden: true },
    auth: false,
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/error/404.vue"),
    meta: { hidden: true },
    auth: false,
  },
  {
    path: "/:pathMatch(.*)", //路由错误
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 刷新时，滚动条位置还原
  scrollBehavior: () => ({ left: 0, top: 0 }),
});

router.beforeEach((to, from, next) => {
  BeforeEnter(to, from, next, routes);
});

/**
 * 重置路由
 */
export const resetRouter = () => {
  router.replace({ path: "/" });
};
export default router;
