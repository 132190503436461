<template>
  <router-view />
  <a href="https://beian.miit.gov.cn/" target="_blank" class="bah"
    >冀ICP备2022027186号-1</a
  >
</template>
<script lang="ts" setup></script>
<style lang="scss" scope>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
}

#app:after {
  background-image: url(https://img.xjh.me/random_img.php?type=bg&ctype=acg&return=302);
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.06;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bah {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #838383;
  font-size: 12px;
}
</style>
