import { defineStore } from "pinia";
import { store } from "@/store";
import { getApi, postApi } from "@/utils/axios";
import { User } from "@/types";

const useUserStore = defineStore({
  id: "useUserStore",
  state: () => ({
    id: "",
    name: "",
    test: "",
  }),
  actions: {
    setUser(user: User) {
      this.id = user.id;
      this.name = user.name;
      this.test = user.test;
    },
    resetUser() {
      this.$reset();
    },
  },
  getters: {
    getUser(state) {
      return state;
    },
  },
});
const useTokenStore = defineStore({
  id: "useTokenStore",
  state: () => ({
    token: "",
  }),
  actions: {
    setToken(token: string) {
      localStorage.setItem("token", token);
      this.token = token;
    },

    resetToken() {
      localStorage.removeItem("token");
      this.$reset();
    },
  },
  getters: {
    getToken(state) {
      return state.token;
    },
  },
});
const userStore = useUserStore();
const tokenStore = useTokenStore();
// 登录
const login = function (loginData: any) {
  return new Promise<void>((resolve, reject) => {
    postApi("/api/login", loginData)
      .then((res: any) => {
        const { userInfo, RedisToken } = res.data;
        userStore.setUser(userInfo);
        tokenStore.setToken(RedisToken);
        resolve();
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
// 根据token获取用户信息
const getUserByToken = function () {
  const token = localStorage.getItem("token") || "";
  tokenStore.setToken(token);
  return new Promise<void>((resolve, reject) => {
    getApi("/api/getUserByToken", {})
      .then((res: any) => {
        const { userInfo } = res.data;
        userStore.setUser(userInfo);
        resolve();
      })
      .catch((err: any) => {
        location.reload(); // 清空路由
        tokenStore.resetToken();
        userStore.resetUser();
        reject(err);
      });
  });
};
// 注销
const logout = function () {
  return new Promise<void>((resolve, reject) => {
    postApi("/api/logout", {})
      .then(() => {
        location.reload(); // 清空路由
        tokenStore.resetToken();
        userStore.resetUser();
        resolve();
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export { useUserStore, useTokenStore, login, getUserByToken, logout };

export function useTokenStoreHook() {
  return useTokenStore(store);
}
