import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/logo.png'
import _imports_1 from '@/assets/image/stars.png'
import _imports_2 from '@/assets/image/moon.png'
import _imports_3 from '@/assets/image/sun.png'


const _hoisted_1 = {
  class: "page-main",
  "data-400": "padding-top: 200px",
  "data-600": "padding-top: 0px"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "cards" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

import {
  getCurrentInstance,
  nextTick,
  onDeactivated,
  onMounted,
  ref,
} from "vue";
import $router from "@/router";
import Card from "@/components/Card.vue";
import skrollr from "skrollr";

import { useTokenStore } from "@/store/modules/user";


export default /*@__PURE__*/_defineComponent({
  __name: 'Main',
  setup(__props) {

const skrollrobj = ref(null);
const cardList = ref([
  {
    dataImage: new URL("@/assets/image/Canyons.avif", import.meta.url).href,
    title: "Canyons",
    text: "Enter the literature module.",
    router: "elaina",
    type: 1,
  },
  {
    dataImage: new URL("@/assets/image/Beaches.avif", import.meta.url).href,
    title: "Beaches",
    text: "Enter the picture module.",
    router: "ai",
    type: 2,
  },
  {
    dataImage: new URL("@/assets/image/Trees.avif", import.meta.url).href,
    title: "Trees",
    text: "Enter the video module.",
    type: 3,
  },
  {
    dataImage: new URL("@/assets/image/Lakes.avif", import.meta.url).href,
    title: "Lakes",
    text: "One more card to play with.",
    type: 4,
  },
]);
const useToken = useTokenStore();
// const fileList = ref([])
const fType = ref(0);
const { proxy } = getCurrentInstance() as any;

onMounted(() => {
  nextTick(() => {
    skrollrobj.value = skrollr.init({
      forceHeight: false,
      smoothScrolling: true,
      smoothScrollingDuration: 500,
      easing: {
        vibrate: function (p: number) {
          return Math.sin(p * 10 * Math.PI);
        },
      },
    });
  });
});
onDeactivated(() => {
  const instance = skrollr.get();
  instance.destroy();
});
const run = function (type: number) {
  if (useToken.token) {
    fType.value = type;
    proxy.$refs.uploadBtn.click();
  }
};
const toRouter = function (item: { router: any }) {
  if (item.router) {
    $router.push({
      path: item.router,
    });
  }
};

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"sky skrollable rendered\" data-0=\"background: rgb(74,74,74); top: 0px\" data-300=\"background: rgb(140,201,220); top: 0px\" data-600=\"top: -200px\" style=\"background:rgb(74, 74, 74);\"><div class=\"sky-inner\"><div class=\"title\"><img src=\"" + _imports_0 + "\" alt=\"Elaina\" class=\"logo\"><img src=\"" + _imports_1 + "\" alt=\"Stars\" class=\"stars skrollable rendered\" data-100=\"top: 10%; left: 22%;\" data-300=\"top: -100%; left: 30%;\" style=\"top:10%;left:22%;\"><img src=\"" + _imports_2 + "\" alt=\"Moon\" class=\"moon skrollable rendered\" data-100=\"top: 10%;\" data-300=\"top: -100%;\" style=\"top:10%;\"><img src=\"" + _imports_3 + "\" alt=\"Sun\" class=\"sun skrollable unrendered\" data-200=\"top: 100%; right: 20%;\" data-300=\"top: 10%; right: 0%;\" style=\"top:100%;right:20%;\"></div></div></div><h2 class=\"wel-title\">我是！我是！我是！我是一只哈士奇！</h2>", 2)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cardList.value, (item, index) => {
          return (_openBlock(), _createBlock(Card, {
            key: index,
            "data-image": item.dataImage
          }, {
            header: _withCtx(() => [
              _createElementVNode("h1", {
                onClick: ($event: any) => (toRouter({ router: item.router }))
              }, _toDisplayString(item.title), 9, _hoisted_4)
            ]),
            content: _withCtx(() => [
              _createElementVNode("a", {
                onClick: ($event: any) => (run(item.type))
              }, _toDisplayString(item.text), 9, _hoisted_5)
            ]),
            _: 2
          }, 1032, ["data-image"]))
        }), 128))
      ])
    ]),
    _createVNode(_component_router_view)
  ]))
}
}

})